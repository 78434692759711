import {useEffect, useState} from "react";
import {getConfig} from "../../controller/utils";
import {axiosInstance} from "../../utils/API";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";

export default function UserAddresses({headerTitle, onAddressSelected, chosenAddress}) {
    const [formShown, showForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [shipmentOptions, setShipmentOptions] = useState([]);

    const [mobileCountry, setMobileCountry] = useState('ae');
    const [searchTerm, setSearchTerm] = useState('');
    const [citySearchTerm, setCitySearchTerm] = useState('');
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);


    const [address, setAddress] = useState({
        firstName: '',
        lastName: '',
        email: '',
        street: '',
        building: '',
        city: '',
        country: '',
        postalCode: '',
        phoneNumber: ''
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setAddress(prevState => ({
            ...prevState,
            [name]: value
        }));

        console.log(address);
    };

    useEffect(() => {
        getCountries();
        getAllAddresses().then((r) => {
            setAddresses(r.data?.result?.rows)
        })
    }, []);

    useEffect(() => {
        if (addresses && addresses.length > 0)
            onAddressSelected(addresses[0])

    }, [addresses])

    const handleChangePhoneCode = async (value, data, event, formattedValue) => {

        console.log("dialcode", data.dialCode);
        console.log(formattedValue)
        let phoneNumber = value
        console.log("phoneNumber", phoneNumber)

        setAddress(prevState => ({
            ...prevState,
            phoneNumber: phoneNumber
        }));
    }


    const getCountries = () => {
        const config = getConfig();

        var result = [];
        axiosInstance.get('/iCarry/GetAllCountry', config)
            .then((r) => {
                r.data.result.countries.map((c) => {
                    if(c.AllowsShipping){
                        result.push({
                            label: c.Name,
                            value: c.Name,
                            id: c.Id,
                            iso2: c.TwoLetterIsoCode,
                            iso3: c.ThreeLetterIsoCode
                        })
                    }
                })
                console.log(result);
                if (result?.length) setCountries(result);
            })
    };

    const getCitiesByCountry = (countryId) => {
        const config = getConfig();

        var result = [];
        axiosInstance.get(`/iCarry/GetStatesByCountryId/${countryId}`, config)
            .then((r) => {
                setCities(r.result);
                r.data.result.map((c) => {
                    result.push({
                        label: c.name,
                        value: c.name,
                        id: c.Id,
                    })
                });
                if (result?.length) setCities(result);
            })
    }

    const getAllAddresses = () => {
        const config = getConfig();
        return axiosInstance.get('/user/getAllShippingAddresses', config)
    }

    const saveAddress = (e) => {
        e.preventDefault();
        setLoading(true);
        const config = getConfig();

        const payload = {
            "firstName": address.firstName,
            "lastName": address.lastName,
            "email": address.email,
            "phoneNumber": address.phoneNumber,
            "countryName": address.country,
            "address1": `${address.street} ${address.building}`,
            "address2": address.city,
            "zipPostalCode": address.postalCode
        }
        return axiosInstance
            .post(`/user/addShippingAddress`, payload, config)
            .then((res) => {
                showForm(false);
                getAllAddresses().then((r) => {
                    setAddresses(r.data?.result?.rows)
                })

            }).catch((err) => {
                if (err?.response?.status) {
                    alert(err.response?.data?.message);
                }
            }).finally((res) => {
                console.log(res);
                setLoading(false);
            });

    }

    return (

        <div className={'deliveryForm'}>
            <h4 className={'text-white'}>Select a delivery address</h4>
            <div className={'p-2'}>
                {addresses.map((address) => {
                    return <div className={'text-white my-2 d-flex flex-row align-items-center'}>
                        <input type={'radio'} checked={address == chosenAddress} name={'shippingOption'}
                               onClick={() => onAddressSelected(address)}/>
                        <div className={'mx-2 my-0'}>
                            {address.firstName} {address.lastName}<br/>
                            {address.address1}, {address.address2}, {address.countryName}<br/>
                            Phone Number: {address.phoneNumber}
                        </div>

                    </div>
                })}
                {!formShown && <Button className={'addDeliveryBtn'} onClick={() => showForm(true)}>Add new address</Button>}
            </div>
            {formShown &&
                <Form onSubmit={saveAddress} aria-disabled={loading}>
                    <Container>
                        <Row className={'text-white mt-3'}>
                            <Col><h4>New Address</h4></Col>
                        </Row>
                        <Row>
                            <Col md={6} className={'my-2'}>

                                <Form.Group controlId={'country'}>
                                    <Form.Label>Country*</Form.Label>
                                    <Select
                                        menuPlacement="bottom"
                                        options={countries}
                                        value={searchTerm}
                                        placeholder={'Select Country'}
                                        onChange={(e) => {
                                            setSearchTerm(e);
                                            setAddress(prevState => ({
                                                ...prevState,
                                                country: e.label
                                            }));
                                            setCitySearchTerm('');
                                            getCitiesByCountry(e.id);
                                            setMobileCountry(e.iso2.toLowerCase())
                                        }}
                                        styles={{
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#FFF'
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                color: 'white', // Change font color of options based on focus state,
                                                backgroundColor: '#3e2f5f'
                                            }),
                                            singleValue: (provided, state) => ({
                                                ...provided,
                                                color: 'white'
                                            }),
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: '#3e2f5f',
                                                borderColor: '#FFF',
                                                color: 'white'
                                            }),
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="street">
                                    <Form.Label>Phone Number*</Form.Label>
                                    <PhoneInput
                                        enableSearch={true}
                                        country={mobileCountry}
                                        value={address.phoneNumber}
                                        onChange={handleChangePhoneCode}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter first name"
                                        name="firstName"
                                        value={address.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter last name"
                                        name="lastName"
                                        value={address.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email Address*</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email address"
                                        name="email"
                                        value={address.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="city">
                                    <Form.Label>City*</Form.Label>
                                    <Select
                                        menuPlacement="bottom"
                                        options={cities}
                                        value={citySearchTerm}
                                        defaultValue={'Select City'}
                                        placeholder={'Select City'}
                                        onChange={(e) => {
                                            setCitySearchTerm(e);
                                            setAddress(prevState => ({
                                                ...prevState,
                                                city: e.label
                                            }));
                                        }}
                                        styles={{
                                            input: (provided) => ({
                                                ...provided,
                                                color: '#FFF'
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                color: 'white', // Change font color of options based on focus state,
                                                backgroundColor: '#3e2f5f'
                                            }),
                                            singleValue: (provided, state) => ({
                                                ...provided,
                                                color: 'white'
                                            }),
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: '#3e2f5f',
                                                borderColor: '#FFF',
                                                color: 'white'
                                            }),
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="street">
                                    <Form.Label>Street Address*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter street address"
                                        name="street"
                                        value={address.street}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className={'my-2'}>
                                <Form.Group controlId="building">
                                    <Form.Label>Building Name / Number*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter building name and number "
                                        name="building"
                                        value={address.building}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={() => showForm(false)}>Cancel</Button>
                            </Col>
                            <Col className={'m-0 mt-3'}>
                                <Button variant="primary" type="submit" className={'claimButton submit'}
                                        disabled={loading}>
                                    {loading ? 'Saving...' : 'Save address'}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            }
        </div>
    )
}
